.input-block {
    width: 100%;
    height: auto;
    padding: 9px 15px 11px;
    border: 1px solid #171C1E;
    position: relative;
    margin-bottom: 20px;
}

select {
    padding-inline-start: 0px!important;
    outline: none!important;
    box-shadow: none!important;
}

.react-daterange-picker__wrapper {
    height: 56px;
    border-color: #171C1E;
    border-radius: 0px;
    padding: 0 10px;
    font-size: 15px;
}