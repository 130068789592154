.averly-date-picker .header .iconBackground {
    border-radius: 3px;
    background-color: transparent;
}

.averly-date-picker .header .iconBackground:hover {
    background-color: #344054;
}

.averly-date-picker .header .iconBackground .icon {
    color: #000
}

.averly-date-picker .header .iconBackground:hover .icon {
    color: #fff;
}